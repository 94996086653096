var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tCheckStation" },
    [
      _c(
        "Row",
        {
          staticStyle: {
            "flex-wrap": "nowrap",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          },
          attrs: { gutter: 4 },
        },
        [
          _vm.expand
            ? _c(
                "Col",
                { style: _vm.leftSpan },
                [
                  _c("Alert", { staticClass: "alert" }, [_vm._v("人员列表")]),
                  _c("Card", { staticClass: "card" }, [
                    _c(
                      "div",
                      { staticStyle: { overflow: "hidden", height: "100%" } },
                      [
                        _c(
                          "Row",
                          {
                            staticStyle: {
                              "margin-bottom": "10px",
                              display: "flex",
                            },
                          },
                          [
                            _c(
                              "RadioGroup",
                              {
                                staticStyle: { width: "50%" },
                                on: { "on-change": _vm.radioCheckChange },
                                model: {
                                  value: _vm.isCheck,
                                  callback: function ($$v) {
                                    _vm.isCheck = $$v
                                  },
                                  expression: "isCheck",
                                },
                              },
                              [
                                _c("Radio", { attrs: { label: 0 } }, [
                                  _c("span", [_vm._v("未检")]),
                                ]),
                                _c("Radio", { attrs: { label: 1 } }, [
                                  _c("span", [_vm._v("已检")]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "50%",
                                  "text-align": "right",
                                },
                              },
                              [
                                _c(
                                  "i-switch",
                                  {
                                    staticStyle: { "text-align": "center" },
                                    attrs: { size: "large" },
                                    on: { "on-change": _vm.typeChange },
                                    model: {
                                      value: _vm.typeStatus,
                                      callback: function ($$v) {
                                        _vm.typeStatus = $$v
                                      },
                                      expression: "typeStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { attrs: { slot: "open" }, slot: "open" },
                                      [_vm._v("复查1")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "close" },
                                        slot: "close",
                                      },
                                      [_vm._v("非复查")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.queryTime
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "nowrap",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "RadioGroup",
                                  {
                                    staticClass: "btngroup",
                                    attrs: {
                                      type: "button",
                                      size: "small",
                                      "button-style": "solid",
                                    },
                                    on: { "on-change": _vm.pick },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  },
                                  [
                                    _c("Radio", { attrs: { label: "当日" } }),
                                    _c("Radio", { attrs: { label: "当月" } }),
                                    _c(
                                      "DatePicker",
                                      {
                                        attrs: {
                                          open: _vm.datePickerShow,
                                          transfer: "",
                                          type: "daterange",
                                        },
                                        on: {
                                          "on-change": _vm.datePickerChange,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            [
                                              _c("Radio", {
                                                attrs: { label: "自定义" },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    _vm.datePickerShow =
                                                      !_vm.datePickerShow
                                                  },
                                                },
                                              }),
                                            ],
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "resetBtn",
                                    staticStyle: { cursor: "pointer" },
                                    on: { click: _vm.resetClick },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "nowrap",
                                  "align-items": "center",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    type: "date",
                                    transfer: "",
                                    placeholder: "开始时间",
                                  },
                                  on: { "on-change": _vm.datePickerStartTime },
                                  model: {
                                    value: _vm.groupPersonSearchForm.startDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "startDate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "groupPersonSearchForm.startDate",
                                  },
                                }),
                                _vm._v("  ~  "),
                                _c("DatePicker", {
                                  attrs: {
                                    type: "date",
                                    transfer: "",
                                    placeholder: "结束时间",
                                  },
                                  on: { "on-change": _vm.datePickerEndTime },
                                  model: {
                                    value: _vm.groupPersonSearchForm.endDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "endDate",
                                        $$v
                                      )
                                    },
                                    expression: "groupPersonSearchForm.endDate",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "Row",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c("Input", {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                placeholder: "请输入姓名",
                                clearable: "",
                              },
                              on: {
                                "on-clear": function ($event) {
                                  return _vm.inputSearchChange("")
                                },
                              },
                              nativeOn: {
                                keypress: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.inputSearchChange.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: _vm.groupPersonSearchForm.personName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.groupPersonSearchForm,
                                    "personName",
                                    $$v
                                  )
                                },
                                expression: "groupPersonSearchForm.personName",
                              },
                            }),
                            _vm.drop
                              ? _c("Input", {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    placeholder: "请输入体检编号",
                                    clearable: "",
                                  },
                                  on: {
                                    "on-clear": function ($event) {
                                      return _vm.inputSearchChange("")
                                    },
                                  },
                                  nativeOn: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.inputSearchChange.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.groupPersonSearchForm.testNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "testNum",
                                        $$v
                                      )
                                    },
                                    expression: "groupPersonSearchForm.testNum",
                                  },
                                })
                              : _vm._e(),
                            _vm.drop
                              ? _c("Input", {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    placeholder: "请输入单位名称",
                                    clearable: "",
                                  },
                                  on: {
                                    "on-clear": function ($event) {
                                      return _vm.inputSearchChange("")
                                    },
                                  },
                                  nativeOn: {
                                    keypress: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.inputSearchChange.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.groupPersonSearchForm.dept,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.groupPersonSearchForm,
                                        "dept",
                                        $$v
                                      )
                                    },
                                    expression: "groupPersonSearchForm.dept",
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "Button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.inputSearchChange },
                              },
                              [_vm._v("查询 ")]
                            ),
                            !_vm.queryTime
                              ? _c(
                                  "Button",
                                  {
                                    attrs: { size: "small" },
                                    on: { click: _vm.resetClick },
                                  },
                                  [_vm._v("重置 ")]
                                )
                              : _vm._e(),
                            _c(
                              "a",
                              {
                                staticClass: "drop-down",
                                on: { click: _vm.dropDown },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.dropDownContent) + " "),
                                _c("Icon", {
                                  attrs: { type: _vm.dropDownIcon },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "Row",
                          {
                            staticStyle: { "justify-content": "center" },
                            style: {
                              height: "calc(100% - " + _vm.height1 + ")",
                            },
                          },
                          [
                            _vm.personData.length > 0
                              ? _c(
                                  "RadioGroup",
                                  {
                                    staticClass: "radio-group",
                                    model: {
                                      value: _vm.isRadioChecked,
                                      callback: function ($$v) {
                                        _vm.isRadioChecked = $$v
                                      },
                                      expression: "isRadioChecked",
                                    },
                                  },
                                  _vm._l(_vm.personData, function (item) {
                                    return _c(
                                      "Row",
                                      {
                                        key: item.id,
                                        staticClass: "row-border",
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.radioChooseClick(
                                              item.id,
                                              item.physicalType
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "Col",
                                          {
                                            staticClass: "radio-group-radio",
                                            attrs: { span: 4 },
                                          },
                                          [
                                            _c("Radio", {
                                              attrs: {
                                                label: item.id,
                                                disabled: _vm.disRadio,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "Col",
                                          {
                                            staticClass: "radio-group-content",
                                            attrs: { span: 15 },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  width: "80px",
                                                  overflow: "hidden",
                                                  "text-overflow": "ellipsis",
                                                  "white-space": "nowrap",
                                                },
                                                attrs: {
                                                  title: item.personName,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.personName) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-right": "6px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.sex))]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  padding: "0 8px 0 4px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.age))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "Col",
                                          { attrs: { span: 5 } },
                                          [
                                            _c(
                                              "Row",
                                              { attrs: { gutter: 6 } },
                                              [
                                                _c(
                                                  "Col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    item.physicalType &&
                                                    item.physicalType.indexOf(
                                                      "职业"
                                                    ) != -1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-blue",
                                                          },
                                                          [_vm._v(" 职 ")]
                                                        )
                                                      : _vm._e(),
                                                    item.physicalType &&
                                                    item.physicalType.indexOf(
                                                      "健康"
                                                    ) != -1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-green",
                                                          },
                                                          [_vm._v(" 健 ")]
                                                        )
                                                      : _vm._e(),
                                                    item.physicalType &&
                                                    item.physicalType.indexOf(
                                                      "从业"
                                                    ) != -1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-yellow",
                                                          },
                                                          [_vm._v(" 从 ")]
                                                        )
                                                      : _vm._e(),
                                                    item.physicalType &&
                                                    item.physicalType.indexOf(
                                                      "放射"
                                                    ) != -1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-purple",
                                                          },
                                                          [_vm._v(" 放 ")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "Col",
                                                  { attrs: { span: 12 } },
                                                  [
                                                    item.sporadicPhysical &&
                                                    item.sporadicPhysical == 1
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-purple",
                                                          },
                                                          [_vm._v(" 零 ")]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border-purple",
                                                          },
                                                          [_vm._v("团")]
                                                        ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              : _c("no-data"),
                            _vm.checkPersonLoading
                              ? _c(
                                  "Spin",
                                  { attrs: { fix: "" } },
                                  [
                                    _c("Icon", {
                                      staticClass: "demo-spin-icon-load",
                                      attrs: {
                                        type: "ios-loading",
                                        size: "18",
                                      },
                                    }),
                                    _c("div", [_vm._v("加载中...")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.personData.length > 0
                              ? _c("Page", {
                                  attrs: {
                                    current:
                                      _vm.groupPersonSearchForm.pageNumber,
                                    total: _vm.personTotal,
                                    simple: "",
                                  },
                                  on: { "on-change": _vm.personChangePageNum },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "expand" },
            [
              _c("Icon", {
                staticClass: "icon",
                attrs: { type: _vm.expandIcon, size: "16" },
                on: { click: _vm.changeExpand },
              }),
            ],
            1
          ),
          _c(
            "Col",
            {
              staticStyle: { height: "100%", overflow: "hidden" },
              style: _vm.span,
            },
            [
              _vm.personInfoLoading
                ? _c(
                    "Spin",
                    { attrs: { fix: "" } },
                    [
                      _c("Icon", {
                        staticClass: "demo-spin-icon-load",
                        attrs: { type: "ios-loading", size: "18" },
                      }),
                      _c("div", [_vm._v("加载中...")]),
                    ],
                    1
                  )
                : [
                    _vm.personData.length == 0
                      ? _c(
                          "Card",
                          [
                            _c("no-data", {
                              staticStyle: {
                                height: "calc(100vh - 128px)",
                                overflow: "hidden",
                                display: "flex",
                                "flex-direction": "row",
                                "justify-content": "center",
                              },
                              attrs: { width: "150" },
                            }),
                          ],
                          1
                        )
                      : [
                          _c(
                            "Collapse",
                            {
                              model: {
                                value: _vm.showCollapsePanels,
                                callback: function ($$v) {
                                  _vm.showCollapsePanels = $$v
                                },
                                expression: "showCollapsePanels",
                              },
                            },
                            [
                              _c(
                                "Panel",
                                {
                                  attrs: { name: "info" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "content",
                                      fn: function () {
                                        return [
                                          _c(
                                            "person-info",
                                            {
                                              attrs: {
                                                "person-info": _vm.personInfo,
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "buttons" },
                                                _vm._l(
                                                  _vm.buttons,
                                                  function (item) {
                                                    return _c(
                                                      "Button",
                                                      _vm._b(
                                                        {
                                                          key: item.content,
                                                          staticStyle: {
                                                            width: "150px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEntityActions(
                                                                item.event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "Button",
                                                        item,
                                                        false
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.content) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "700" } },
                                    [_vm._v("体检人员信息")]
                                  ),
                                  !_vm.showCollapsePanels.length
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "32px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "16px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 姓名: " +
                                                  _vm._s(
                                                    _vm.personInfo
                                                      .person_name ||
                                                      _vm.personInfo.personName
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "16px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 性别: " +
                                                  _vm._s(_vm.personInfo.sex)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "16px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "年龄: " +
                                                  _vm._s(_vm.personInfo.age) +
                                                  "岁"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                  ],
              _c("Card", { style: _vm.cardTabStyle }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingGroup,
                        expression: "loadingGroup",
                      },
                    ],
                    staticStyle: { height: "100%" },
                  },
                  [
                    _c(
                      "otherInfo",
                      {
                        class: _vm.otherInfoClass,
                        attrs: {
                          "hidden-footer": _vm.hiddenTabsFooter,
                          "person-info": _vm.personInfo,
                        },
                        on: {
                          handleSearch: _vm.refreshCheckStatus,
                          "tab-click": _vm.handleTabClick,
                        },
                        model: {
                          value: _vm.tabsValue,
                          callback: function ($$v) {
                            _vm.tabsValue = $$v
                          },
                          expression: "tabsValue",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "tabs" },
                          _vm._l(_vm.tabsArr, function (item, index) {
                            return _c(
                              "el-tab-pane",
                              {
                                key: index,
                                attrs: {
                                  lazy: true,
                                  disabled: _vm.loading,
                                  label: _vm.initTabName(item.name),
                                  name: item.id,
                                },
                              },
                              [
                                [
                                  _c(
                                    "div",
                                    {
                                      ref: item.id,
                                      refInFor: true,
                                      staticClass: "tab-pane-content",
                                    },
                                    [
                                      item.isFile == "是"
                                        ? _c(
                                            "div",
                                            {
                                              style: `height: ${
                                                _vm.tableHeight - 3
                                              }px ;  overflow-y: auto`,
                                            },
                                            [
                                              _c(
                                                "Row",
                                                {
                                                  staticStyle: {
                                                    border: "1px solid #dcdee2",
                                                    "border-top": "0",
                                                    overflow: "hidden",
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "Col",
                                                    {
                                                      staticStyle: {
                                                        height: "100%",
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                        "justify-content":
                                                          "center",
                                                        "align-items": "center",
                                                      },
                                                      attrs: { span: "24" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "flex-shrink": "0",
                                                          },
                                                        },
                                                        [
                                                          _c("Table", {
                                                            key: Math.random(),
                                                            attrs: {
                                                              loading:
                                                                _vm.loading,
                                                              border: "",
                                                              columns:
                                                                _vm.getColumnsYX(),
                                                              sortable:
                                                                "custom",
                                                              data: _vm.data,
                                                              "row-class-name":
                                                                _vm.rowClassName,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "viewer",
                                                              rawName:
                                                                "v-viewer",
                                                              value: {
                                                                movable: false,
                                                                zIndex: 7000,
                                                              },
                                                              expression:
                                                                "{\n                              movable: false,\n                              zIndex: 7000,\n                            }",
                                                            },
                                                          ],
                                                          staticStyle: {
                                                            flex: "1",
                                                            width: "100%",
                                                            display: "flex",
                                                            "justify-content":
                                                              "center",
                                                            "align-items":
                                                              "center",
                                                            "overflow-y":
                                                              "auto",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.pacsData,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c("img", {
                                                              key: index,
                                                              staticStyle: {
                                                                border:
                                                                  "1px dashed #ccc",
                                                                "margin-right":
                                                                  "20px",
                                                                width: "25vw",
                                                                height: "250px",
                                                              },
                                                              attrs: {
                                                                src: item,
                                                              },
                                                            })
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      item.isFile == "否" ||
                                      item.isFile == null ||
                                      item.isFile == ""
                                        ? _c(
                                            "div",
                                            [
                                              _c("Table", {
                                                key: Math.random(),
                                                attrs: {
                                                  loading: _vm.loading,
                                                  border: "",
                                                  columns:
                                                    item.name.indexOf(
                                                      "肺功能"
                                                    ) > -1
                                                      ? _vm.getColumnsFGN()
                                                      : _vm.getColumns(),
                                                  sortable: "custom",
                                                  data: _vm.data,
                                                  "row-class-name":
                                                    _vm.rowClassName,
                                                  height: _vm.tableHeight,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "Form",
                                        {
                                          staticStyle: {
                                            margin: "16px 2px 0",
                                            "border-bottom": "0",
                                          },
                                          attrs: {
                                            "label-width": 30,
                                            inline: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "Row",
                                            {
                                              staticStyle: {
                                                "flex-wrap": "nowrap",
                                              },
                                            },
                                            [
                                              _c(
                                                "Col",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                  },
                                                  attrs: { span: "10" },
                                                },
                                                [
                                                  _c(
                                                    "Button",
                                                    {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        right: "-4px",
                                                        bottom: "1px",
                                                        "z-index": "8",
                                                        height: "22px",
                                                        padding: "0 10px",
                                                      },
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleWordChoose(
                                                            "zdtx",
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选 ")]
                                                  ),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "600",
                                                        "margin-bottom": "6px",
                                                      },
                                                    },
                                                    [
                                                      item.isFile == "是"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                                "font-size":
                                                                  "16px",
                                                                "margin-right":
                                                                  "6px",
                                                                position:
                                                                  "relative",
                                                                top: "4px",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "体检提醒或影像所见"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("Input", {
                                                    attrs: {
                                                      type: "textarea",
                                                      placeholder:
                                                        "输入多个以;分隔",
                                                      autosize: {
                                                        maxRows: 6,
                                                        minRows: 6,
                                                      },
                                                    },
                                                    on: {
                                                      "on-focus": function (
                                                        $event
                                                      ) {
                                                        return _vm.handlediagnoseTipData(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.departResult
                                                          .diagnoseTip,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item.departResult,
                                                          "diagnoseTip",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.departResult.diagnoseTip",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("Col", {
                                                attrs: { span: "4" },
                                              }),
                                              _c(
                                                "Col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "16px",
                                                  },
                                                  attrs: { span: "10" },
                                                },
                                                [
                                                  _c(
                                                    "Button",
                                                    {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        left: "0px",
                                                        bottom: "1px",
                                                        "z-index": "8",
                                                        height: "22px",
                                                        padding: "0 10px",
                                                      },
                                                      attrs: {
                                                        disabled: _vm.disRadio,
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleCrisisData(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("自动小结 ")]
                                                  ),
                                                  _c(
                                                    "Button",
                                                    {
                                                      staticStyle: {
                                                        position: "absolute",
                                                        right: "-4px",
                                                        bottom: "1px",
                                                        "z-index": "8",
                                                        height: "22px",
                                                        padding: "0 10px",
                                                      },
                                                      attrs: {
                                                        disabled: _vm.disRadio,
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleWordChoose(
                                                            "zdxj",
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选 ")]
                                                  ),
                                                  _c(
                                                    "label",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px",
                                                        "font-weight": "600",
                                                        "margin-bottom": "6px",
                                                      },
                                                    },
                                                    [
                                                      item.isFile == "是"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                                "font-size":
                                                                  "16px",
                                                                "margin-right":
                                                                  "6px",
                                                                position:
                                                                  "relative",
                                                                top: "4px",
                                                              },
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v("体检小结"),
                                                    ]
                                                  ),
                                                  _c("Input", {
                                                    attrs: {
                                                      type: "textarea",
                                                      placeholder:
                                                        "输入多个以;分隔",
                                                      autosize: {
                                                        maxRows: 6,
                                                        minRows: 6,
                                                      },
                                                    },
                                                    on: {
                                                      "on-change": function (
                                                        $event
                                                      ) {
                                                        return _vm.diagnoseSumChange(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.departResult
                                                          .diagnoseSum,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item.departResult,
                                                          "diagnoseSum",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.departResult.diagnoseSum",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Form",
                                    {
                                      staticClass: "action-form",
                                      attrs: { "label-width": 90 },
                                    },
                                    [
                                      _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            "flex-wrap": "nowrap",
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            { attrs: { span: "5" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "检查医生" },
                                                },
                                                [
                                                  _c(
                                                    "Select",
                                                    {
                                                      staticClass: "input",
                                                      attrs: {
                                                        filterable: true,
                                                        "label-in-value": "",
                                                        transfer: "",
                                                      },
                                                      on: {
                                                        "on-select":
                                                          _vm.doctorSelectChange,
                                                      },
                                                      model: {
                                                        value:
                                                          item.departResult
                                                            .createId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item.departResult,
                                                            "createId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.departResult.createId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.userArray,
                                                      function (itemD, index) {
                                                        return _c(
                                                          "Option",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              value: itemD.id,
                                                              label:
                                                                itemD.nickname,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  itemD.nickname
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            { attrs: { span: "5" } },
                                            [
                                              _c(
                                                "FormItem",
                                                {
                                                  attrs: { label: "检查日期" },
                                                },
                                                [
                                                  _c("DatePicker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      type: "datetime",
                                                      transfer: "",
                                                      placeholder:
                                                        "请选择检查日期",
                                                    },
                                                    model: {
                                                      value:
                                                        item.departResult
                                                          .checkDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item.departResult,
                                                          "checkDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.departResult.checkDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "Col",
                                            [
                                              _c(
                                                "ButtonGroup",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content": "center",
                                                    "margin-left": "18px",
                                                  },
                                                },
                                                [
                                                  item.isFile == "是"
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.uploadImageClick(
                                                                item.officeId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 上传图片 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.name.indexOf("测听") >
                                                    -1 &&
                                                  _vm.isShowCalculationButton
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          attrs: {
                                                            type: "primary",
                                                            loading:
                                                              _vm.syncLoading,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.calculation,
                                                          },
                                                        },
                                                        [_vm._v("计算偏移值 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.specimen != null
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              item.status ==
                                                                1 ||
                                                              item.status == 2
                                                                ? false
                                                                : true,
                                                            type: "primary",
                                                            loading:
                                                              _vm.syncLoading,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.syncResult,
                                                          },
                                                        },
                                                        [_vm._v("同步结果 ")]
                                                      )
                                                    : _vm._e(),
                                                  item.departResult.id ||
                                                  _vm.indxArr.indexOf(index) >
                                                    -1
                                                    ? _c(
                                                        "Button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              item.status ==
                                                                1 ||
                                                              item.status == 2
                                                                ? false
                                                                : true,
                                                            type: "primary",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleViewTemplate,
                                                          },
                                                        },
                                                        [_vm._v("报告预览 ")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "Button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          item.status == 1 ||
                                                          item.status == 2
                                                            ? false
                                                            : true,
                                                        type: "success",
                                                        loading:
                                                          _vm.saveLoading,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSave(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("保存结果 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                              ],
                              2
                            )
                          }),
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _c("drawer", {
                attrs: {
                  isSearchPositive: _vm.isUserOfficeTerm
                    ? false
                    : _vm.isSearchPositive,
                  modalTitle: _vm.title,
                  type: _vm.type,
                  inspectType:
                    _vm.personInfo.physical_type || _vm.personInfo.physicalType,
                  officeId: _vm.officeId,
                  portfolioProjectId: _vm.portfolioProjectId,
                  hazardFactors:
                    _vm.personInfo.hazard_factors ||
                    _vm.personInfo.hazardFactors,
                  hazardFactorsText:
                    _vm.personInfo.hazard_factors_text ||
                    _vm.personInfo.hazardFactorsText,
                },
                on: { getSelectedWords: _vm.getSelectedWords },
                model: {
                  value: _vm.wordChooseShow,
                  callback: function ($$v) {
                    _vm.wordChooseShow = $$v
                  },
                  expression: "wordChooseShow",
                },
              }),
              _c("preview-template", {
                attrs: { personInfo: _vm.personInfo },
                model: {
                  value: _vm.previewShow,
                  callback: function ($$v) {
                    _vm.previewShow = $$v
                  },
                  expression: "previewShow",
                },
              }),
              _c("review", {
                attrs: {
                  modalTitle: _vm.title,
                  groupItemId: _vm.groupItemId,
                  personInfo: _vm.personInfo,
                },
                on: { getItemDataByGroupId: _vm.getItemDataByGroupId },
                model: {
                  value: _vm.reviewShow,
                  callback: function ($$v) {
                    _vm.reviewShow = $$v
                  },
                  expression: "reviewShow",
                },
              }),
              _c("data-base-upload", {
                model: {
                  value: _vm.dataBaseUploadShow,
                  callback: function ($$v) {
                    _vm.dataBaseUploadShow = $$v
                  },
                  expression: "dataBaseUploadShow",
                },
              }),
              _c("upload-image", {
                on: { handleUploadImage: _vm.handleUploadImage },
                model: {
                  value: _vm.isShowUploadImage,
                  callback: function ($$v) {
                    _vm.isShowUploadImage = $$v
                  },
                  expression: "isShowUploadImage",
                },
              }),
              _c("sample-code", {
                attrs: { data: _vm.tabsArr, personInfo: _vm.personInfo },
                model: {
                  value: _vm.sampleCodeShow,
                  callback: function ($$v) {
                    _vm.sampleCodeShow = $$v
                  },
                  expression: "sampleCodeShow",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: "弃检原因", "mask-closable": false, closable: false },
          model: {
            value: _vm.abandonRensonShow,
            callback: function ($$v) {
              _vm.abandonRensonShow = $$v
            },
            expression: "abandonRensonShow",
          },
        },
        [
          _c(
            "label",
            [
              _c("Input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 5 },
                  placeholder: "请输入弃检原因...",
                },
                model: {
                  value: _vm.abandonRenson,
                  callback: function ($$v) {
                    _vm.abandonRenson = $$v
                  },
                  expression: "abandonRenson",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.abandonRensonShow = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.checkLoading },
                  on: { click: _vm.abandonRensonClick },
                },
                [_vm._v("提交 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }